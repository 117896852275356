<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Upload External Cost</h4>

        <vue-dropzone
            id="mainImage"
            ref="mainImage"
            :use-custom-slot="true"
            :options="dropzoneOptions"
        >
          <div class="dz-message needsclick">
            <i class="h1 text-muted ri-upload-cloud-2-line"></i>
            <h3>Upload main image</h3>
          </div>
        </vue-dropzone>

      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";


export default {
  name: "upload-int-cost",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data(){
    return {
      dropzoneOptions: {
        url: '#',
        addRemoveLinks: true,
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        autoProcessQueue: false,
        maxFiles: 1,
      },
    }
  }
}
</script>

<style scoped>

</style>